<template>
  <section class="el-drawer-detail-container">
    <div class="user-center-wrap">
      <!-- certificate -->
      <div class="certificate" v-cloak ref="imageWrapper">
        <div class="img"><img src="../../../assets/images/zhengshu_up.png" class="certificate-title" /></div>
        <div class="cert-content">
          <div class="cert-uesr respect">
            尊敬的
            <p class="zunjingde">{{ nameShowed }}&nbsp;</p>&nbsp;:
          </div>
          <br>
          <p>感谢您对济南慈善事业的关心和支持。</p>
          <div class="cert-uesr">由您捐赠的<p class="money">{{ moneyCount }}</p>元善款，我们将依据《中华人民共和国慈善法》等有关法律、法规，用于发展我市的慈善事业。
          </div>
          <p>对您崇高的人道主义精神瑾表示诚挚的敬意！</p>
        </div>
        <div class="cert-time">
          <div class="cert-name">
            <div class="seal"><img src="@/assets/images/seal.png" /></div>
            <p>济南慈善总会</p>
            <p>{{ formatTime }}</p>
          </div>
        </div>
        <!--      <img class="certificate-ewm" src="@/static/img/ewm.jpg" />-->
        <img class="certificate-logo" src="../../../assets/images/zhengshu_down.png" />
      </div>
    </div>
    <div class="download-btn text-center"><el-button type="primary" @click="onExport()">下载证书</el-button></div>
  </section>

</template>

<script>
import api from "../../../api";
import moment from "moment";
import html2Canvas from 'html2canvas'

export default {
  name: "donationsCertificate",
  // async asyncData(ctx) {
  //   let id = ctx.query.fundIncomeRecordId //string
  //   const fetch1 = await api.fundIncomeRecord.get({id: Number(id)})
  //   return {
  //     name: fetch1.donorName,
  //     moneyCount: fetch1.moneyAmount,
  //     time: fetch1.incomeTime
  //   }
  // },

  computed: {
    formatTime() {
      return this.time ? moment(this.time).format('YYYY年MM月DD日') : 'N/A'
    },
    nameShowed() {
      return this.name ? this.name : '爱心人士'
    }
  },
  data() {
    return {
      name: '',
      moneyCount: 0,
      time: ''
    };
  },
  async created() {
    console.log(this.$route.params, "==============");
    const fetch1 = await api.fundIncomeRecord.get({id: Number(this.$route.params.id)})
    this.name= fetch1.donorName
    this.moneyCount=fetch1.moneyAmount
    this.time=fetch1.incomeTime
  },
  methods:{
    onExport() {
      const clientWidth = this.$refs.imageWrapper.offsetWidth;
      const clientHeight = this.$refs.imageWrapper.offsetHeight;
      const kh = [clientWidth, clientHeight];

      html2Canvas(this.$refs.imageWrapper, { useCORS: true, logging: true }).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png');
        this.download(dataURL, kh);
      });
    },
    getUrlBase64(url, kh) {
      return new Promise((resolve) => {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = () => {
          // eslint-disable-next-line prefer-destructuring
          canvas.height = kh[1];
          // eslint-disable-next-line prefer-destructuring
          canvas.width = kh[0];
          ctx.drawImage(img, 0, 0, kh[0], kh[1]);
          const dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    download(imgUrl, kh) {
      this.getUrlBase64(imgUrl, kh).then((base64) => {
        const link = document.createElement('a');
        link.href = base64;
        link.download = `证书.png`;
        link.click();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  visibility: hidden !important;
}

.user-center-wrap {
  padding-bottom: 0 !important;
  /* certificate-证书详情 */
  .certificate {
    width: 838px;
    height: 1188px;
    margin: 30px auto;
    background: url('../../../assets/images/zhengshu_bg.png');
    background-size: 100% 100%;
    position: relative;
    padding: 155px 96px;

    .img{
      text-align: center;
      .certificate-title {
        width: 390px;
      }
    }
  }

  .certificate-logo {
    position: absolute;
    bottom: 88px;
    left: 50%;
    width: 120px;
    transform: translate(-50%, -50%);
  }

  .cert-top {
    text-align: center;
    margin-bottom: 50px;
  }

  .cert-title {
    color: #074076;
    font-size: 30px;
    font-weight: bold;
  }

  .cert-etitle {
    color: #074076;
    font-size: 20px;
    font-weight: bold;
  }

  .cert-content {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .cert-uesr {
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    text-indent: 2em;

    .zunjingde {
      width: auto;
      text-indent: 1em;
    }

    .money {
      width: 100px;
      text-indent: 0em;
    }
  }

  .respect {
    text-indent: 0em;
  }

  .cert-content p {
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    text-indent: 2em;
  }

  .cert-uesr p {
    display: inline-block;
    width: auto;
    border-bottom: 1px solid #211613;
    text-align: center;
  }

  .cert-time {
    width: 164px;
    float: right;
    position: relative;
    text-align: center;
  }

  .cert-chapter {
    width: 150px;
    height: 150px;
  }

  .cert-name {
    position: absolute;
    top: 40px;
    left: 6px;
    .seal{
      position: absolute;
      width: 150px;
      height: 150px;
      left: -23px;
      top: -11px;
      img{
        opacity: .6;
        width: 100%;
        height: 100%;
      }
    }
  }

  .cert-name p {
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
  }

  .el-carousel__button {
    background-color: #409eff !important;
  }

}
</style>
